/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "./custom-theme";
// @import "../node_modules/angular-calendar/css/angular-calendar.css"; //calendar
// @import "~ag-grid-community/dist/styles/ag-grid.css"; //ag-grid
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; //ag-grid
@import url("./assets/css/normalize.css");
@import url("./assets/css/components.css");
@import url("./assets/css/rank-checker-report.css"); // change name acording to new report.

html,
body,
p {
    font-family: "Noto Sans", Sans-serif;
}
body,
html {
    height: 100%;
    width: 100%;
    margin: 0;
   scroll-behavior: smooth;

}

body {
    margin: 0;
    padding: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


body {
    background: #f7f7f7;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
   scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: #d9d2e9;
    background: #e7e7e7;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8e7cc3;
}

#button,
button {
    background-color: #7e84f2;
    color: white;
    cursor: pointer;
}

#button:hover,
button:hover {
    background-color: #1b2246;
    color: white;
    cursor: pointer;
}

#button:disabled,
button:disabled {
    background-color: #e7e7e7;
    color: gray;
    cursor: not-allowed;
}

a,
icon {
    color: #7e84f2;
    cursor: pointer;
}

a:hover,
icon:hover {
    color: #1b2246;
    cursor: pointer;
}

#pageTitle,
h1 {
    color: #1b2246;
    font-weight: 500;
}

#subPageTitle {
    color: #1b2246;
    font-weight: 500;
    font-size: 20px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #7e84f2 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #7e84f2 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #7e84f2;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #1b2246;
}
::ng-deep .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: #7e84f2 !important;
}

.btn-primary,
.mat-raised-button.mat-primary {
    color: #fff;
    background-color: #7e84f2;
    border-color: #7e84f2;
}

.btn-primary:hover,
.mat-raised-button.mat-primary:hover {
    color: #fff;
    background-color: #1b2246;
    border-color: #1b2246;
}

.mat-menu-item:hover:not([disabled]) {
    color: #fff;
    background-color: #7e84f2;
}

.mat-raised-button {
    color: #fff;
    background-color: #7e84f2;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #7e84f2;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #7e84f2 !important;
    background: #7e84f2 !important;
}
.p-datatable .p-sortable-column.p-highlight {
    border-color: #7e84f2 !important;
    background: #7e84f2 !important;
    color: #ffffff;
}

mat-progress-spinner {
    color: #1b2246;
}
.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.2rem #7e84f2 !important;
}
.p-datatable .p-sortable-column {
    outline-color: #7e84f2 !important;
}
#pTable {
    margin: 20px;
    padding: 1%;
    background-color: white;
}

.ui-table table {
    table-layout: auto !important;
}

::ng-deep .mat-checkbox .mat-checkbox-frame {
    border-color: #7e84f2;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background {
    background-color: #7e84f2 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #7e84f2;
}

.p-button {
    color: #ffffff;
    background: #7e84f2 !important;
    border: 1px solid #7e84f2 !important;
}

.p-button:enabled:active,
.p-button:enabled:hover {
    background: #1b2246 !important;
    color: #ffffff;
    border-color: #1b2246 !important;
}
// primeng tab
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #7e84f2 !important;
    border-color: #7e84f2 !important;
    color: #ffffff;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:hover,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:active {
    background: #1b2246 !important;
    border-color: #1b2246 !important;
    color: #ffffff;
}
// ---------------prime ng scrollbar----------
.custombar1 .ui-scrollpanel-wrapper {
    border-right: 8px solid white;
}

.custombar1 .ui-scrollpanel-bar-y {
    background-color: #858688;
    opacity: 1;
    transition: background-color 0.3s;
}

.custombar1 .ui-scrollpanel-bar-y:hover {
    background-color: #1b2246;
}

.custombar1 .ui-scrollpanel-bar-x:hover,
.custombar1 .ui-scrollpanel-bar-x {
    display: none;
}
// ------calendar-week-month-----
.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #1b2246 !important;
    border-color: #1b2246 !important;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #1b2246 !important;
    border-color: #1b2246 !important;
    box-shadow: 0 0 0 0.2rem #1b2246 !important;
}
// -----------------------------------
.mat-snack-bar-container-error {
    font-size: 40px;
    font-weight: 400;
    width: 300px;
    height: 50px;
    background-color: red;
    color: white;
}

p {
    padding: 10px;
    margin: 10px;
}

h1 {
    font-family: "Roboto";
    font-size: x-large;
    font-weight: 500;
}

mat-icon {
    vertical-align: bottom;
}

.fa {
    padding: 10px;
    font-size: 20px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    margin: 2px 2px;
    border-radius: 50%;
    margin-right: 5px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
    background: #3b5998;
    color: white;
}

.fa-google {
    background: #dd4b39;
    color: white;
}

.fa-globe {
    background-color: #228b22;
    color: white;
}

/*    .fa-check{
        background: #3B5998;
        color: white;
    }
    .fa-ban{
        background: #3B5998;
        color: white;
    }

    .fa-eye-slash{
        background: #3B5998;
        color: white;
    }

    .fa-repeat{
        background: #3B5998;
        color: white;
    }*/
// Center Mat-Tabs horizontally across the screen
/*.mat-tab-links {
    display: table;
    width: 100%;

    .mat-tab-link {
        display: table-cell;
    }
}*/
.timeslot-button {
    width: 200px;
    height: 40px;
    padding: 5px;
    margin: 10px;
    font-size: 16px;
    font-weight: 400;
}

/*
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element
{
      opacity: 0.5 !important;
      background-color: blue !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle
{
      background-color: blue!important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle
{
     border-color:blue!important;
}

.abc
{
  border-color:blue!important;
}
*/

button:focus,
button:active:focus,
button.active:focus {
    outline: none !important;
    outline-style: none !important;
}

.view {
    min-height: 84vh;
    width: 100%;
    padding: 0 0.5rem;
    margin: 0.625px;
}

.format-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.equal-cols {
    width: 50%;
}

.trapezoid {
    border-top: 400px solid transparent;
    border-left: 100px solid red;
    border-right: 100px solid red;
    //height: 0;
    //width: 100px;
}

.triangle-topright {
    width: 0;
    height: 0;
    border-top: 100px solid red;
    border-left: 100px solid transparent;
}

.triangle-bottomleft {
    //width: 0;
    //height: 0;
    border-bottom: 400px inset lightgray;
    border-right: 100px inset transparent;
    text-align: center;
}

.triangle-bottomright {
    //width: 450px;
    //height: 1200px;
    border-bottom: 400px inset lightgray;
    border-left: 100px inset transparent;
    text-align: center;
}

.oval {
    width: 100%;
    height: 20px;
    background: transparent;
    -moz-border-radius: 100px / 50px;
    -webkit-border-radius: 100px / 50px;
    border-radius: 100px / 50px;
    border-style: dotted;
    border-color: red;
    border-width: 2px;
}

/* Cleaner, but slightly less support: use "50%" as value */

.rectangle {
    width: 200px;
    height: 100px;
    background: red;
    text-align: center;
    //vertical-align: center;
    //justify-content: center;
}

.footer {
    font-size: 0.75em;
    font-weight: lighter;
    align-items: center;
    justify-content: center;
}

.privacy-policy {
    font-size: 0.9em;
    text-align: justify;
}

.time-period {
    text-align: right;
    font-size: 0.75em;
}

.spacer {
    flex: 1 1 auto;
}

// .mat-card-title {

// }
.metric-card {
    flex: 0 0 auto;
    min-width: 200px;
    min-height: 75px;
    margin: 5px;
    padding: 5px;
}

.metric-caption {
    text-transform: uppercase;
    text-align: center;
    vertical-align: top;
    font-size: 0.75em;
    line-height: 1em;
}

.metric {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 400;
    text-shadow: 1px 1px #dddddd;
    text-align: center;
    vertical-align: middle;
}

.metric.kpi {
    font-size: 1.5em;
    line-height: 2em;
}

.table-outline {
    border: 2px solid #f7f7f7;
    box-shadow: 1px 1px 1px #e7e7e7;
    margin-top: 2em;
    width: 100%;
}

.table-outline td,
th {
    border: 1px solid #ffffff;
    padding: 10px 30px;
}

.info-cards {
    margin: 10px;
    padding: 5px;
    min-width: 500px;
    min-height: 500px;
}

.ad-preview-cards {
    margin: 5px;
    padding: 5px;
    max-width: 320px;
}

.tip-cards {
    margin: 3px;
    padding: 1px;
}

.example-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
}

.example-field {
    padding: 5px;
}

.example-full-width {
    width: 100%;
}

.mat-select {
    width: 100% !important;
    font-size: 0.75em !important;
    font-weight: normal !important;
}

/* Navigation*/

.navbar {
    background-color: transparent;
}

.scrolling-navbar {
    -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    -moz-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.top-nav-collapse {
    background-color: #1c2331;
}

footer.page-footer {
    //background-color: #1C2331;
    margin-top: 0;
}

/* Carousel*/

.carousel,
.carousel-item,
.active {
    height: 100%;
}

.carousel-inner {
    height: 100%;
}

.carousel-caption {
    height: 100%;
    padding-top: 7rem;
}

.disable-channel {
    height: auto;
    width: auto;
    margin: 10px;
    padding: 10px;
    position: relative;
    z-index: 1;
    background-color: #ddd;
    opacity: 0.7;
    pointer-events: none;
}

.screen-shots {
    min-height: 600px;
}

.scrolling-wrapper-flexbox {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 5px;

    //padding: 5px;
    .card {
        flex: 0 0 auto;
        min-width: 150px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.cal-month-view .cal-day-badge {
    background-color: #7e84f2;
}

.mat-button:focus,
.mat-menu-item:focus,
:focus {
    outline: none;
}

.icon-btn {
    color: #7e84f2;
    cursor: pointer;
    font-size: 18px;
    margin: 0;
    vertical-align: middle;
}

.icon-btn:hover {
    color: #1b2246;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #7e84f2;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: #7e84f2;
    background-color: #7e84f2;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: #7e84f2;
}

@media only screen and (max-width: 768px) {
    .navbar {
        background-color: #1c2331;
    }

    .spacer {
        flex: 1 1 auto;
    }
}

/*ALl cards*/

@media screen and (max-width: 1200px) and (min-width: 300px) {
    .info-cards {
        margin: 10px;
        padding: 0px;
        min-width: 250px;
        min-height: 300px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    /* Device = Most of the Smartphones Mobiles (Portrait) */
    /*     .mat-dialog-container{
             padding:0px;
         }*/
    .custom-dialog-container .mat-dialog-container {
        padding: 0px;
    }
}

/*.mat-menu-panel{
  overflow: hidden !important;

}
*/
.center {
    height: fit-content;
    width: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.custom-form-preview-dialog-container .mat-dialog-container {
    padding: 0;
    overflow-y: hidden;
}

#subHeader1 {
    background-color: white;
    padding: 1em;
}

::ng-deep .matTooltip {
    font-size: large;
}

::ng-deep .mat-horizontal-stepper {
    background-color: #7e84f2;
}

::ng-deep .mat-step {
    background-color: #7e84f2;
}

ng-deep .mat-badge-content {
    font-family: "Material Icons";
}
