.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

p {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
}

.overview {
  position: relative;
  display: block;
  overflow: visible;
  height: 100vh;
  margin-top: 0vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 0%;
  background-color: #ebedf5;
}

.header {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 0px;
  padding-bottom: 1vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
}

.div-block-7 {
  display: inline-block;
  padding-left: 0px;
  text-align: left;
}

.div-block-16 {
  background-color: transparent;
}

.list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  list-style-type: none;
}

.list-item {
  padding-right: 10px;
  padding-left: 10px;
}

.link-block {
  color: #1b2246;
  font-size: 1.6vh;
  line-height: 3vh;
  text-transform: uppercase;
  cursor: pointer;
}

.link-block:hover {
  color: #7f86e7;
}

.link-block:active {
  border-bottom: 2px none #cbcfff;
}

.link-block.w--current {
  border-bottom: 2px none #cbcfff;
  font-size: 1.6vh;
}

.link-block.w--current:active {
  border-bottom-style: solid;
  border-bottom-color: #cbcfff;
}

.icon {
  display: inline-block;
  font-family: 'Font awesome 5 free solid 900', sans-serif;
  font-size: 1.6vh;
}

.bold-text {
  font-weight: 400;
}

.menu-item {
  display: inline-block;
  padding-left: 5px;
  font-size: 1.6vh;
  line-height: 3vh;
}

.bookappointmentpopup {
  position: static;
  padding: 10px 20px;
  border-style: none;
  border-color: #7f86e7 #66f #7f86e7 #7f86e7;
  border-right-width: 1px;
  background-color: #66f;
  font-family: Roboto, sans-serif;
  color: #f5f5ff;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.bookappointmentpopup:hover {
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  color: #66f;
  text-decoration: none;
}

.progressbar {
  width: 25%;
  height: 3px;
  background-color: #7f86e7;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  line-height: 3px;
}

.paragraph-8 {
  display: inline-block;
  margin-bottom: 0px;
  font-size: 14px;
}

.grid-3 {
  margin-top: 5px;
  padding-top: 0px;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 0.5fr 0.75fr;
  grid-template-columns: 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 9px;
  background-color: rgba(203, 207, 255, 0.14);
  text-align: left;
}

.paragraph-3 {
  margin-bottom: 0px;
  font-size: 2.2vh;
  line-height: 3vh;
}

.div-block-15 {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border: 0px none #000;
  border-radius: 0px;
  background-color: #fff;
}

.seconds {
  font-size: 1.9vh;
  line-height: 2.6vh;
  text-align: center;
}

.grid-4 {
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 0.5fr 0.75fr;
  grid-template-columns: 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 9px;
  background-color: #cbcfff;
  text-align: left;
}

.knob {
  display: inline-block;
  margin: 0px;
  padding: 0px 5px 0px 10px;
}

.text-block-3 {
  display: inline-block;
  padding-top: 0px;
  border-right: 1px none transparent;
  border-left-style: none;
  border-left-color: #54588d;
  font-size: 15px;
  line-height: 20px;
}

.google-ranking {
  position: relative;
  display: block;
  height: 100vh;
  margin-top: 5%;
  background-color: #f7f7f7;
}

.google-ranking:active {
  border-bottom: 1px solid #cbcfff;
}

.score {
  margin: 0px;
  padding: 10px 0px;
  font-size: 3vh;
  line-height: 1.8vh;
  text-align: center;
}

.reputation {
  position: relative;
  height: 100vh;
  margin-top: 5%;
  background-color: #f7f7f7;
}

.website-performance {
  position: relative;
  display: block;
  height: 100vh;
  margin-top: 5%;
  background-color: #f7f7f7;
}

.column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #ebedf5;
}

.paragraph-6 {
  margin: 0px;
  padding: 0px 0px 0px 10px;
  font-size: 2.2vh;
  text-align: left;
  text-transform: capitalize;
}

.div-block-8 {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.stacked-bar-graph {
  height: 60vh;
}

.name {
  display: inline-block;
  margin: 0px;
  padding: 0px 0px 0px 2.2vh;
  font-size: 2.2vh;
  line-height: 3vh;
}

.html-embed {
  display: none;
  text-align: left;
}

.grid-5 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.button {
  position: absolute;
  left: auto;
  top: 10px;
  right: 0%;
  bottom: auto;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #7f86e7;
  background-color: #fff;
  color: #7f86e7;
  font-size: 12px;
  text-transform: uppercase;
}

.button:hover {
  border-style: solid;
  border-color: #7f86e7;
  background-color: #7f86e7;
  color: #fff;
}

.button.filled-btn {
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  border-right-color: #66f;
  background-color: #66f;
  font-family: 'Font awesome 5 free solid 900', sans-serif;
  color: #fff;
  font-size: 2vh;
  line-height: 3vh;
  text-decoration: none;
}

.button.filled-btn:hover {
  background-color: #fff;
  color: #7f86e7;
}

.button.filled-btn.common-width {
  margin: 0px;
  font-size: 2vh;
  line-height: 3vh;
}

.button.see-details-btn {
  top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  border-color: #66f;
  font-family: 'Font awesome 5 free solid 900', sans-serif;
  color: #66f;
  font-size: 1.8vh;
  line-height: 2.6vh;
  text-decoration: none;
}

.button.see-details-btn:hover {
  background-color: #66f;
  color: #fff;
}

.result.outer-padding {
  padding-top: 80px;
  padding-right: 60px;
  padding-left: 60px;
}

.overlay-content {
  font-size: 2.2vh;
  line-height: 3vh;
  text-align: justify;
}

.result-details.visible-on-mobile {
  display: none;
}

.result-details.outer-padding {
  display: block;
  padding-top: 80px;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.review-count {
  margin-bottom: 0px;
  text-align: center;
}

.sectiontitle {
  margin: 2.2vh 0px 1.6vh;
  padding: 0px;
  color: #000;
  font-size: 4vh;
  line-height: 5vh;
  font-weight: 400;
}

.sectiontitle.visible-on-mobile {
  display: none;
}

.calculation-details {
  margin-top: 2.2vh;
  margin-bottom: 1.2vh;
  padding: 1.2vh;
  border-radius: 30px;
  background-color: #fff;
  color: #596080;
  font-size: 2.2vh;
  line-height: 3vh;
  font-weight: 400;
  text-align: justify;
}

.page-performance-table {
  padding-top: 0px;
  background-color: transparent;
}

.close-button {
  position: absolute;
  left: auto;
  top: 10%;
  right: 6%;
  bottom: auto;
  display: inline-block;
  padding: 0px;
  background-color: transparent;
  color: #54588d;
  font-size: 40px;
  font-weight: 300;
  cursor: pointer;
}

.close-button:hover {
  color: #7f86e7;
}

.div-block-20 {
  padding-top: 0px;
}

.total-page-score {
  display: block;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 0px 1.6vh;
  padding: 0.5vh 0vh;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 30px;
  background-color: #fff;
  font-size: 0px;
  line-height: 0px;
  text-decoration: none;
}

.reputation-overlay {
  position: absolute;
  left: 50%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: scroll;
}

.overlay-screen {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  overflow: auto;
  height: 100vh;
  padding-top: 60px;
  padding-right: 60px;
  padding-left: 60px;
  border-bottom: 1px solid #cbcfff;
  border-left: 3px solid #cbcfff;
  background-color: #fff;
}

.paragraph-7 {
  margin: 0px;
  padding: 5px 0px 5px 10px;
  color: #000;
  font-size: 2.2vh;
  font-weight: 700;
  text-align: left;
}

.columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90vh;
}

.div-block-10 {
  text-align: right;
}

.text-block-4 {
  display: inline-block;
  margin-top: 0px;
  font-family: 'Font awesome 5 free solid 900', sans-serif;
  color: #fbbc05;
  font-size: 1.7vh;
  line-height: 3vh;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f7f7f7;
}

.paramter-title {
  display: inline-block;
  font-size: 1.6vh;
  line-height: 2.6vh;
  text-align: center;
}

.mobile-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 10vh;
  margin-top: 0px;
  padding-left: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ebedf5;
}

.website-performance-overlay {
  position: absolute;
  left: 50%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: scroll;
}

.review {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right-style: none;
  border-right-color: #f5f5f5;
  border-left-style: none;
  border-left-color: #f5f5f5;
  text-align: center;
}

.review.borders {
  border-right: 1px solid #e7e5e5;
  border-left: 1px solid #e7e5e5;
  text-align: center;
}

.scroll-snap-container {
  display: block;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
}

.html-embed-2 {
  display: none;
}

.card {
  padding: 10px;
  border-radius: 15px;
  background-color: rgba(203, 207, 255, 0.14);
  text-align: center;
}

.bold-text-2 {
  padding-left: 5px;
  color: #fbbc05;
  line-height: 15px;
  font-weight: 400;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-30 {
  padding-right: 5px;
  padding-left: 5px;
  background-color: transparent;
}

.google-ranking-overlay {
  position: absolute;
  left: 50%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: visible;
}

.div-block-5 {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.body {
  font-family: Roboto, sans-serif;
}

.heading-3 {
  margin: 2vh 0px 0px;
  padding: 0px;
  color: #7f86e7;
  font-size: 2.2vh;
  line-height: 5vh;
  font-weight: 500;
  text-transform: uppercase;
}

.practicename {
  margin: 0px 0px 1vh;
  padding: 0px;
  color: #000;
  font-size: 3.4vh;
  line-height: 5vh;
  font-weight: 500;
  text-align: center;
}

.practiceaddress {
  margin: 0px 0px 1vh;
  padding: 0px;
  color: #000;
  font-size: 3.4vh;
  line-height: 5vh;
  font-weight: 500;
  text-align: center;
}

.practice-details-wrapper {
  text-align: center;
}

.subtitle {
  margin: 0px 0px 0vh;
  padding: 0px;
  color: #596080;
  font-size: 2.6vh;
}

.div-block-33 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.paragraph-11 {
  margin: 0px;
  padding: 0px;
  font-size: 2.2vh;
  line-height: 3vh;
  text-align: center;
}

.div-block-34 {
  position: static;
  padding: 0px;
  background-color: transparent;
  color: #fff;
  text-align: center;
}

.overall-scrore-wrapper {
  padding-top: 0px;
}

.bold-text-4 {
  color: #000;
  font-size: 4vh;
  line-height: 6vh;
  font-weight: 500;
}

.paragraph-12 {
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 2.2vh;
  line-height: 4vh;
  font-weight: 400;
  text-align: center;
}

.div-block-38 {
  position: relative;
  display: block;
  height: 5vh;
  margin-right: 10px;
  margin-left: 50px;
  padding: 5px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border: 1px solid #cbcfff;
  border-radius: 15px;
}

.image {
  position: absolute;
  left: 3%;
  top: auto;
  right: auto;
  bottom: 15%;
}

.image-2 {
  position: absolute;
  left: auto;
  top: 15%;
  right: 3%;
  bottom: auto;
  margin-top: 5px;
}

.image-3 {
  position: absolute;
  left: auto;
  top: 15%;
  right: 10%;
  bottom: auto;
  margin-top: 5px;
}

.paragraph-13 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin: 8px 0px 0px 10px;
  padding: 0px;
  color: #596080;
  text-align: left;
}

.heading-4 {
  margin: 2vh 0px 1vh;
  padding: 0px;
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
}

.see-details {
  font-size: 14px;
  text-decoration: none;
}

.div-block-40 {
  position: relative;
}

.bold-text-6 {
  font-size: 1.6vh;
  line-height: 2.6vh;
  text-align: center;
}

.paragraph-14 {
  margin-bottom: 20px;
  font-size: 2.2vh;
  line-height: 3vh;
}

.paragraph-15 {
  margin-top: 20px;
  font-size: 2.2vh;
  line-height: 3vh;
}

.div-block-44 {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 10vh;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #ebedf5;
}

.columns-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 5555;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.59);
  -o-object-fit: fill;
  object-fit: fill;
}

.modal-content {
  position: relative;
  display: block;
  max-width: 1000px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fff;
}

.close-modal {
  position: absolute;
  left: auto;
  top: 0%;
  right: 2%;
  bottom: auto;
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  text-decoration: none;
}

.heading-22 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: #7f86e7;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
}

.div-block-48 {
  height: 500px;
  max-height: 500px;
  background-color: #fff;
}

.text-block-6 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}

.new-practice-details {
  display: none;
}

.text-block-7 {
  display: inline-block;
  font-size: 4.2vh;
  line-height: 3vh;
}

.text-block-8 {
  display: inline-block;
  margin-left: 10px;
  font-size: 1.8vh;
  line-height: 3vh;
  letter-spacing: 5px;
}

.review-copy {
  margin-left: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
  border-right-style: none;
  border-right-color: #f5f5f5;
  border-left-style: none;
  border-left-color: #f5f5f5;
  text-align: left;
}

.column-5 {
  margin-top: 10px;
  border-right: 1px none #cecece;
}

.text-block-9 {
  margin-left: 25px;
  text-align: left;
}

.column-6 {
  border-left: 1px solid #cecece;
}

.grid-4-copy {
  padding-top: 5px;
  padding-bottom: 5px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 0.5fr 0.75fr;
  grid-template-columns: 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 9px 9px 0px 0px;
  background-color: #cbcfff;
  text-align: left;
}

.grid-3-copy {
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 0.5fr 0.75fr;
  grid-template-columns: 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 0px;
  background-color: rgba(203, 207, 255, 0.14);
  text-align: left;
}

.text-block-9-copy {
  margin-left: 0px;
  font-size: 1.8vh;
  text-align: center;
}

.grid-3-copy-copy {
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 0.5fr 0.75fr;
  grid-template-columns: 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 0px 0px 9px 9px;
  background-color: rgba(203, 207, 255, 0.14);
  text-align: left;
}

.mob-view {
  display: none;
}

.mob-fixed-buttons {
  display: none;
}

.image-5 {
  width: 50%;
}

.columns-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-for-video-2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 5555;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.59);
  -o-object-fit: fill;
  object-fit: fill;
}

.modal-content-video-2 {
  position: relative;
  display: block;
  max-width: 1000px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fff;
}

.close-modal-video-2 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 2%;
  bottom: auto;
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  text-decoration: none;
}

.modal-2-video-box {
  height: 500px;
  max-height: 500px;
  background-color: #fff;
}

.mob-view-video-2 {
  display: none;
}

.image-6 {
  cursor: pointer;
}

.paragraph-16 {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  font-size: 2.2vh;
  line-height: 3vh;
}

.bold-text-10 {
  line-height: 2.6vh;
}

.bold-text-11 {
  color: #596080;
}

.text-block-11 {
  margin: 0px;
  padding: 0px 0px 0px 1.2vh;
  font-size: 2.2vh;
  font-weight: 700;
}

.column-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f7f7f7;
}

.heading-23.visible-on-mobile {
  display: none;
}

.practicedomain {
  color: #000;
  font-size: 3.4vh;
  line-height: 5vh;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}

@media screen and (min-width: 1280px) {
  .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .link-block.w--current {
    font-size: 1.8vh;
  }

  .icon {
    font-size: 1.8vh;
  }

  .menu-item {
    font-size: 1.8vh;
  }

  .reputation {
    margin-top: 5%;
  }

  .paragraph-6 {
    text-align: center;
  }

  .html-embed {
    display: none;
  }

  .button.filled-btn {
    width: auto;
  }

  .button.filled-btn.w--current {
    width: auto;
    text-align: center;
  }

  .button.filled-btn.common-width {
    width: 80px;
    text-align: center;
  }

  .button.see-details-btn {
    text-decoration: none;
  }

  .reputation-overlay {
    z-index: 1000;
    display: none;
  }

  .paragraph-7 {
    text-align: center;
  }

  .column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .website-performance-overlay {
    z-index: 1000;
  }

  .scroll-snap-container {
    overflow: hidden;
  }

  .google-ranking-overlay {
    z-index: 1000;
    display: none;
  }

  .practicename {
    margin-bottom: 0px;
  }

  .practiceaddress {
    margin-bottom: 0px;
  }

  .image-2 {
    margin-top: 3px;
  }

  .image-3 {
    margin-top: 3px;
  }

  .paragraph-13 {
    overflow: hidden;
    margin-top: 5px;
  }

  .div-block-44 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #ebedf5;
  }

  .modal {
    display: none;
  }

  .modal-content {
    display: block;
  }

  .text-block-6 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
  }

  .new-practice-details {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block-7 {
    display: inline-block;
    margin-top: 20px;
    font-size: 35px;
  }

  .text-block-8 {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    letter-spacing: 5px;
  }

  .review-copy {
    margin-left: 20px;
    padding-bottom: 0px;
    text-align: left;
  }

  .column-5 {
    margin-top: 0px;
    border-right: 1px solid #cecece;
  }

  .text-block-9 {
    margin-left: 25px;
  }

  .column-6 {
    border-left-style: none;
  }

  .grid-3-copy {
    border-radius: 0px;
  }

  .text-block-9-copy {
    margin-left: 0px;
  }

  .image-4 {
    cursor: pointer;
  }

  .mob-fixed-buttons {
    display: none;
  }

  .image-5 {
    width: 50%;
  }

  .column-9 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .columns-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .column-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-11 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .modal-for-video-2 {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 5556;
    display: none;
    overflow: visible;
  }

  .modal-content-video-2 {
    top: 0%;
    display: block;
    width: 1000px;
  }

  .mob-view-video-2 {
    overflow: visible;
  }

  .image-6 {
    margin-left: -10px;
    cursor: pointer;
  }

  .column-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  p {
    font-size: 16px;
  }

  .overview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-left-radius: 0%;
    background-color: #ebedf5;
    background-image: none;
  }

  .list {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .list-item {
    padding-right: 2px;
    padding-left: 2px;
  }

  .link-block {
    text-align: center;
  }

  .bookappointmentpopup {
    padding-right: 10px;
    padding-left: 10px;
    background-color: #7f86e7;
    font-size: 16px;
  }

  .progressbar {
    width: 10%;
    height: 4px;
  }

  .div-block-15 {
    position: relative;
    padding-bottom: 10px;
  }

  .column-2 {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .grid-5 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .button {
    position: absolute;
    padding-right: 10px;
    padding-left: 10px;
  }

  .button.filled-btn {
    position: relative;
    margin-right: 0px;
    font-size: 13px;
  }

  .button.see-details-btn {
    margin-right: 2px;
    font-size: 13px;
  }

  .result.outer-padding {
    display: block;
    padding-top: 50px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .result-details.outer-padding {
    padding-top: 50px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .sectiontitle {
    font-size: 25px;
  }

  .calculation-details {
    margin-bottom: 0px;
  }

  .column {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .mobile-buttons {
    display: block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .scroll-snap-container {
    overflow: hidden;
  }

  .card {
    text-align: center;
  }

  .heading-3 {
    font-weight: 500;
  }

  .practicename {
    color: #000;
    font-size: 25px;
    line-height: 45px;
    text-align: center;
  }

  .practiceaddress {
    color: #000;
    font-size: 25px;
    text-align: center;
  }

  .practice-details-wrapper {
    text-align: center;
  }

  .paragraph-11 {
    text-align: center;
  }

  .div-block-34 {
    background-color: transparent;
  }

  .bold-text-4 {
    color: #000;
    font-size: 25px;
    font-weight: 500;
  }

  .paragraph-12 {
    color: #2e2b2b;
    font-weight: 400;
    text-align: center;
  }

  .div-block-38 {
    margin-left: 40px;
  }

  .image-3 {
    right: 14%;
  }

  .bold-text-8 {
    font-size: 15px;
  }

  .div-block-44 {
    position: static;
    display: block;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
    text-align: left;
  }

  .text-block-8 {
    line-height: 3vh;
  }

  .image-5 {
    width: 100%;
  }

  .column-9 {
    display: none;
  }

  .columns-4 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .column-copy {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .practicedomain {
    font-size: 25px;
  }
}

@media screen and (max-width: 767px) {
  .overview {
    margin-top: 0vh;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #ebedf5;
  }

  .header {
    z-index: 25;
  }

  .div-block-7 {
    padding-left: 20px;
  }

  .div-block-16 {
    display: none;
  }

  .bookappointmentpopup {
    background-color: #66f;
  }

  .div-block-15 {
    position: relative;
  }

  .column-2 {
    height: 100vh;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .html-embed {
    overflow: auto;
  }

  .button {
    display: inline-block;
  }

  .button.w--current {
    margin-right: 0px;
  }

  .button.filled-btn {
    position: relative;
    display: inline-block;
    border-style: solid;
    background-color: #66f;
    color: #fff;
  }

  .button.filled-btn:hover {
    background-color: #fff;
    color: #66f;
  }

  .button.filled-btn.w--current {
    background-color: #66f;
  }

  .button.see-details-btn {
    left: auto;
    top: 2%;
    right: 0%;
    bottom: auto;
  }

  .result.outer-padding {
    padding-top: 60px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .overlay-content {
    margin-bottom: 20px;
  }

  .result-details.visible-on-mobile {
    display: block;
  }

  .sectiontitle.visible-on-mobile {
    display: block;
  }

  .close-button {
    top: 8%;
  }

  .reputation-overlay {
    left: 0%;
    display: none;
  }

  .overlay-screen {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .column {
    display: none;
    height: 100vh;
    padding-right: 0px;
    padding-left: 0px;
  }

  .mobile-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .website-performance-overlay {
    left: 0%;
    z-index: 999;
    display: none;
    height: 100vh;
    background-color: #fff;
  }

  .google-ranking-overlay {
    left: 0%;
    display: none;
  }

  .heading-3 {
    font-size: 16px;
  }

  .practicename {
    font-size: 20px;
    line-height: 5vh;
  }

  .practiceaddress {
    font-size: 20px;
  }

  .div-block-33 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .bold-text-4 {
    font-size: 20px;
  }

  .paragraph-14 {
    line-height: 28px;
  }

  .div-block-44 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
    text-align: left;
  }

  .columns-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .new-practice-details {
    display: block;
  }

  .text-block-7 {
    margin-left: 5px;
  }

  .text-block-8 {
    line-height: 3vh;
  }

  .review-copy {
    margin-left: 0px;
  }

  .text-block-9 {
    margin-left: 8px;
  }

  .text-block-9-copy {
    margin-left: 8px;
  }

  .column-copy {
    display: none;
    height: 100vh;
    padding-right: 0px;
    padding-left: 0px;
  }

  .heading-23.visible-on-mobile {
    display: none;
  }

  .practicedomain {
    font-size: 20px;
  }
}

@media screen and (max-width: 479px) {
  p {
    font-size: 15px;
    text-align: justify;
  }

  .overview {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 100vh;
    margin-top: auto;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    background-color: #ebedf5;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .header {
    height: auto;
    padding: 0px 0px 0.5vh;
    background-color: #fff;
  }

  .div-block-7 {
    display: block;
    margin-top: 10px;
    padding-left: 5px;
  }

  .div-block-16 {
    display: none;
  }

  .bookappointmentpopup {
    margin-top: 5px;
    padding: 1.4vh;
    font-size: 2.2vh;
  }

  .progressbar {
    line-height: 3px;
  }

  .div-block-27 {
    margin-bottom: 10px;
  }

  .paragraph-3 {
    font-size: 1.8vh;
  }

  .div-block-15 {
    margin: 0px 20px;
    padding: 1vh 1.4vh;
  }

  .knob {
    padding-right: 5px;
  }

  .google-ranking {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ebedf5;
  }

  .score {
    padding-bottom: 0px;
  }

  .reputation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    margin-top: 0px;
    padding-top: 0vh;
    padding-bottom: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ebedf5;
  }

  .website-performance {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    margin-top: 0%;
    padding-top: 0vh;
    padding-bottom: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ebedf5;
  }

  .column-2 {
    overflow: scroll;
    height: 100vh;
    padding: 5vh 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .name {
    margin: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    font-size: 1.8vh;
    line-height: 3vh;
  }

  .html-embed {
    display: block;
  }

  .grid-5 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .button {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-right: 0px;
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
  }

  .button.filled-btn {
    top: 0%;
    right: 0%;
    display: inline-block;
    margin-top: 0px;
    margin-right: 10px;
    font-size: 14px;
  }

  .button.filled-btn.hide-on-mobile {
    display: none;
  }

  .button.filled-btn.hide-on-mobile.w--current {
    display: none;
  }

  .button.see-details-btn {
    top: 0vh;
    right: 1vh;
    margin-top: 8px;
    font-size: 1.5vh;
    line-height: 2.6vh;
  }

  .result.outer-padding {
    position: static;
    height: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .overlay-content {
    font-size: 2.2vh;
    line-height: 3.5vh;
  }

  .result-details.visible-on-mobile {
    display: none;
  }

  .review-count {
    padding-left: 10px;
  }

  .sectiontitle {
    margin-top: 0vh;
    margin-bottom: 2vh;
    font-size: 3vh;
    line-height: 5vh;
    text-align: center;
  }

  .sectiontitle.visible-on-mobile {
    display: block;
  }

  .calculation-details {
    margin: 0px;
    padding: 0px;
    font-size: 1.8vh;
    line-height: 3vh;
  }

  .page-performance-table {
    display: block;
    background-color: transparent;
  }

  .close-button {
    top: 0%;
    z-index: 9999;
    display: inline-block;
    padding-top: 10px;
    color: #fff;
    font-weight: 300;
  }

  .div-block-20 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .div-block-3 {
    margin: 0px 0px 1.4vh;
    padding: 0.5vh 0vh;
    line-height: 0px;
  }

  .reputation-overlay {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: none;
    overflow: visible;
    width: 100vw;
    height: 100vh;
  }

  .overlay-screen {
    display: block;
    overflow: auto;
    padding: 5vh 20px 32vh;
    border-bottom-style: none;
    border-left-style: none;
  }

  .paragraph-7 {
    padding-left: 0px;
    color: #000;
    text-align: left;
  }

  .columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    height: 100vh;
    padding-top: 0vh;
    padding-bottom: 0vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #ebedf5;
  }

  .div-block-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    margin-bottom: 1vh;
    margin-left: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .text-block-4 {
    font-size: 1.6vh;
  }

  .column {
    display: none;
    height: auto;
    padding: 0px;
  }

  .paramter-title {
    padding-bottom: 10px;
  }

  .mobile-buttons {
    display: none;
    overflow: visible;
    width: 100%;
    height: auto;
    padding: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #fff;
    text-align: center;
  }

  .website-performance-overlay {
    left: 0%;
    display: none;
    overflow: visible;
    width: 100vw;
  }

  .scroll-snap-container {
    display: block;
    overflow: hidden;
    width: 100vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 20px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .card {
    text-align: center;
  }

  .bold-text-2 {
    font-size: 1.6vh;
    line-height: 3vh;
  }

  .google-ranking-overlay {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1000;
    display: none;
    overflow: visible;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .div-block-5 {
    padding-bottom: 0px;
  }

  .heading-3 {
    margin: 0px;
    padding: 0px;
    font-size: 1.8vh;
    line-height: 3vh;
  }

  .practicename {
    margin: 0px 0px 2vh;
    padding: 0px;
    font-size: 2.4vh;
    line-height: 3.4vh;
    font-weight: 700;
    text-align: center;
  }

  .practiceaddress {
    margin: 0px 0px 2vh;
    padding: 0px;
    font-size: 2.4vh;
    line-height: 3.4vh;
    font-weight: 700;
    text-align: center;
  }

  .practice-details-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .subtitle {
    margin: 0px;
    padding: 0px;
    font-size: 2vh;
    line-height: 3vh;
  }

  .div-block-33 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .paragraph-11 {
    margin: 0px;
    padding: 0px;
    font-size: 1.8vh;
    line-height: 2vh;
    text-align: center;
  }

  .div-block-34 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .overall-scrore-wrapper {
    font-size: 0px;
    line-height: 0px;
  }

  .bold-text-4 {
    display: block;
    margin: 0px;
    padding: 0px;
    font-size: 2vh;
    line-height: 3px;
  }

  .paragraph-12 {
    margin: 0px;
    padding: 0px;
    font-size: 2vh;
    line-height: 3vh;
    text-align: center;
  }

  .div-block-38 {
    margin-top: 5px;
    margin-left: 40px;
    padding-left: 0px;
    border-color: #efeeee;
    color: #6c6868;
  }

  .image {
    left: 3%;
    top: 15%;
    right: auto;
    bottom: auto;
  }

  .image-2 {
    top: 10%;
    right: 2%;
    margin-top: 5px;
  }

  .image-3 {
    top: 10%;
    right: 12%;
    margin-top: 5px;
  }

  .paragraph-13 {
    margin: 0.8vh 0px 0px;
    padding: 0px 0px 0px 1.2vh;
    font-size: 1.6vh;
    line-height: 3vh;
    text-align: left;
  }

  .bold-text-5 {
    padding-right: 2px;
    padding-left: 2px;
  }

  .heading-4 {
    font-size: 2vh;
    line-height: 25px;
  }

  .see-details {
    position: relative;
    left: auto;
    top: auto;
    right: 3%;
    bottom: 31%;
    font-size: 14px;
  }

  .paragraph-14 {
    line-height: 3.6vh;
  }

  .bold-text-7 {
    font-size: 15px;
  }

  .paragraph-15 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .div-block-41 {
    margin-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .bold-text-8 {
    font-size: 1.8vh;
  }

  .div-block-44 {
    display: block;
    height: 0vh;
  }

  .columns-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 90vh;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-45 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .div-block-46 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .modal {
    display: none;
  }

  .div-block-48 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 400px;
    max-height: 400px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block-6 {
    margin: 0px 0px 10px;
    padding: 0px;
    font-size: 2.2vh;
    line-height: 3vh;
  }

  .new-practice-details {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
    padding: 1vh;
    border-radius: 10px;
    background-color: #f8f8f8;
  }

  .text-block-7 {
    font-size: 5.4vh;
  }

  .text-block-8 {
    font-size: 2vh;
    line-height: 3vh;
  }

  .column-5 {
    margin-top: 20px;
    padding-right: 0px;
  }

  .text-block-9 {
    font-size: 1.8vh;
    line-height: 3vh;
  }

  .column-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-left: 1px solid #cecece;
  }

  .image-4 {
    display: none;
  }

  .mob-view {
    display: block;
  }

  .desk-view {
    display: none;
  }

  .button-3 {
    background-color: #596080;
    font-family: 'Font awesome 5 free solid 900', sans-serif;
    font-size: 2.2vh;
    line-height: 3vh;
    text-align: center;
  }

  .column-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .column-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .button-3-copy {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #66f;
    font-size: 2.2vh;
    line-height: 3vh;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .mob-fixed-buttons {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 20;
    display: block;
    overflow: visible;
    margin-top: 0px;
  }

  .div-block-49 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .button-4 {
    background-color: #66f;
    font-size: 2.2vh;
    line-height: 3vh;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .image-5 {
    width: 100%;
  }

  .columns-4 {
    padding-top: 0vh;
  }

  .column-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .modal-for-video-2 {
    display: none;
  }

  .modal-2-video-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 400px;
    max-height: 400px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .desk-view-video-2 {
    display: none;
  }

  .mob-view-video-2 {
    display: block;
  }

  .image-6 {
    display: none;
  }

  .div-block-50 {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0px none #000;
    line-height: 20;
  }

  .bold-text-11 {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .text-block-11 {
    padding-left: 0px;
    color: #596080;
    font-size: 1.8vh;
    line-height: 3vh;
  }

  .column-copy {
    display: none;
    height: auto;
    padding: 0px;
  }

  .heading-23 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 10px;
    font-size: 30px;
    font-weight: 400;
  }

  .heading-23.visible-on-mobile {
    display: block;
    color: #fff;
    font-size: 25px;
    font-weight: 300;
  }

  .overlay-header {
    position: -webkit-sticky;
    position: sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 1100;
    margin-bottom: 0px;
    background-color: #66f;
    color: #000;
  }

  .practicedomain {
    margin-bottom: 2vh;
    font-size: 2.4vh;
    line-height: 3.4vh;
  }
}

#w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b30-a41ceac9 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b33-a41ceac9 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b36-a41ceac9 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b44-a41ceac9 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_92f58315-deeb-d7b0-2375-0820754c30b3-a41ceac9 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_335398fa-2eb5-7d9b-d92a-2d36e0799e33-a41ceac9 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_10d19725-6ac9-a5b4-d560-ee0b542a6545-a41ceac9 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-c4327836-1c9a-ed1d-1884-355361baa526-a41ceac9 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-c4327836-1c9a-ed1d-1884-355361baa540-a41ceac9 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

@media screen and (max-width: 479px) {
  #w-node-c4327836-1c9a-ed1d-1884-355361baa516-a41ceac9 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
}

@font-face {
  font-family: 'Font awesome 5 free solid 900';
  src: url('../fonts/Font-Awesome-5-Free-Solid-900.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Font awesome 5 free 400';
  src: url('../fonts/Font-Awesome-5-Free-Regular-400.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}